/* eslint-disable jsx-a11y/role-supports-aria-props */
import axios from 'axios';
import Scrollbars from 'rc-scrollbars';
import React, { useEffect, useState } from 'react';
import { Dropdown, FloatingLabel, Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import ViewPrimary from "../../images/view-primary.svg";
import client from "../../utils/ApiClient";
import Tab from 'react-bootstrap/Tab';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import { Link, useNavigate } from 'react-router-dom';
import { Admin, CarrierNameSearchText, GetInsuranceCarriers, Manager, statusList, ActiveInactiveList, SystemAdmin, year, BenefitYear, DraftStatus, PublishedStatus, NewStatus, formatDate } from '../../Constants/AppConstants';
import { GetKeyValueFromLocalStorage, RemoveKeyFromLocalStorage, SetKeyToLocalStorage } from '../../Constants/LocalStorage';
import BackArrow from "../../images/back-arrow.svg";
import DownArrow from "../../images/down-arrow.svg";
import FilterMobile from "../../images/filter-mobile.svg";
import Filter from "../../images/filters-primary.svg";
import Add from "../../images/plus-white.svg";
import Search from "../../images/search-small.svg";
import { InsuranceCarriers } from '../../Interfaces/InsuranceCarriers';
import '../../scss/status.scss';
import '../../scss/table.scss';
import '../../scss/tabs.scss';
import { AccessDenied } from '../AccessDenied/AccessDenied';
import { SideBar } from '../SideBar';
import './BenefitConfig.scss';
import './BenefitQuestions.scss';
import './Carriers.scss';
import './StickyNav.scss';
import { NotFound } from './NotFound';

import { EmulateMember } from './EmulateMember';

// Carriers.
export const Carriers = () => {


    // State variable to show activity popup.
    const [showFilters, setShowFilters] = useState(false);

    // State variable to show activity popup.

    // State variable to show activity popup.
    const [showEmulateMember, setshowEmulateMember] = useState(false);
    // State variable for showing or hiding the search box
    const [showSearchBox, setShowSearchBox] = useState(false);

    // Initialize with current screen width
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    // State variable for insurance carriers.
    const [insuranceCarriers, setInsuranceCarriers] = useState<InsuranceCarriers[]>();

    // State variable for Search.
    const [search, setSearch] = useState('');

    // State variable for searchresults.
    const [isNoSearchResult, setIsNoSearchResult] = useState(false);

    // State variable for Loader.
    const [loader, setLoader] = useState(true);

    // State variable for Active Carriers
    const [isActive, setIsActive] = useState(true);

    // State variable for Inactive Carriers
    const [isInActive, setIsInActive] = useState(false);

    // State variable for All Carriers
    const [isAll, setIsAll] = useState(false);

    // State variable for Future Active Carriers
    const [isActiveFuture, setIsActiveFuture] = useState(false);

    // State variable for Future Inactive Carriers
    const [isInActiveFuture, setIsInActiveFuture] = useState(false);

    // State variable for Search filter for Active carriers.
    const [activeFilter, setActiveFilter] = useState(false);

    // State variable for Search filter for InActive carriers.
    const [inActiveFilter, setInActiveFilter] = useState(false);

    // State variable for Search filter for Future Active carriers.
    const [isActiveFutureFilter, setIsActiveFutureFilter] = useState(false);

    // State variable for Search filter for Future InActive carriers.
    const [isInActiveFutureFilter, setIsInActiveFutureFilter] = useState(false);

    /// State variable for redirect token.

    // State variable for insurance carriers.
    const [totalCarriers, setTotalCarriers] = useState<InsuranceCarriers[]>();

    //State variable for selectedStatus.
    const [selectedStatus, setSelectedStatus] = useState<string[]>([]);

    //State variable for filteredStatus.
    const [filteredStatus, setFilteredStatus] = useState<string[]>([]);

    //State variable for isFilterApplied.
    const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);


    //State variable for toggleFitlerVisibility.
    const [toggleFitlerVisibility, setToggleFitlerVisibility] = useState(false);

    //State variable for totalCarriersCount.
    const [totalCarriersCount, setTotalCarriersCount] = useState<number>(0);

    //State variable for selectedCheckBoxStatus.
    const [selectedCheckBoxStatus, setSelectedCheckBoxStatus] = useState<string[]>([]);


    const statusOptions = [statusList.new, statusList.draft, statusList.inProgress, statusList.published];

    // Use history.
    const navigate = useNavigate();

    // Comma-separated text
    const selectedfilterStatusText = selectedCheckBoxStatus.join(', ');

    // Handles the actvity popup close button/icon.
    const handleFiltersClose = () => {
        setShowFilters(false);
    }


    // Function to handle the click event
    const openEmulateMember = () => {
        setshowEmulateMember(true); // This will trigger rendering of the EmulateMember component
    };
    const closeEmulateMember = () => {
        setshowEmulateMember(false); // This will trigger rendering of the EmulateMember component
    };

    // Function to toggle the visibility of the search box
    const toggleSearchBox = () => {
        setShowSearchBox(!showSearchBox);
    };

    // Function to update the screen width state on window resize
    const updateScreenWidth = () => {
        setScreenWidth(window.innerWidth);
    };

    // Add a resize event listener to update the screen width state
    useEffect(() => {
        window.addEventListener("resize", updateScreenWidth);
        return () => window.removeEventListener("resize", updateScreenWidth);
    }, []);

    // Function to handle navigation to the details page
    const navigateToDetailsPage = (insurance: any) => {
        navigate("/CarrierDetails", { state: { activeInsuranceCarrier: insurance } });
    };

    // Handles the insurance carrier and sets the active carrier.
    const handleInsuranceCarrierClick = (insurance: InsuranceCarriers) => {
        navigateToDetailsPage(insurance);
    }


    // Gets the filtered insurance carriers length.
    const getFilteredInsuranceCarriersLength = () => {
        if (search?.length > 2) {
            return insuranceCarriers?.filter(x => x?.insuranceCarrierName?.toLowerCase().includes(search?.toLowerCase()))
                .filter(x => {
                    if (selectedStatus.length === 0) {
                        return true; // No filterstatus, so include all carriers
                    }
                    return selectedStatus.includes(x?.currentYearStatus);



                }).length;
        }
        else {
            return insuranceCarriers?.filter(x => {
                if (selectedStatus.length === 0) {
                    return true; // No filterstatus, so include all carriers
                }
                return selectedStatus.includes(x?.currentYearStatus);
            }).length;
        }
    }

    const getFilteredInsuranceCarrierscCounts = () => {
        if (!insuranceCarriers) {
            return {
                newCount: 0,
                publishedCount: 0,
                draftCount: 0,
            };
        }

        const filteredCarriers = insuranceCarriers.filter((x) => {
            if (search?.length > 2) {
                return (
                    x?.insuranceCarrierName?.toLowerCase().includes(search?.toLowerCase())
                );
            } else {
                return insuranceCarriers;
            }
        });
        if (selectedStatus.length === 0) {
            return {
                newCount: filteredCarriers.filter(
                    (carrier) =>
                        carrier.currentYearStatus.toLowerCase() === NewStatus?.toLowerCase()
                ).length,
                publishedCount: filteredCarriers.filter(
                    (carrier) =>
                        carrier.currentYearStatus.toLowerCase() === PublishedStatus?.toLowerCase()
                ).length,
                draftCount: filteredCarriers.filter(
                    (carrier) =>
                        carrier.currentYearStatus.toLowerCase() === DraftStatus?.toLowerCase()
                ).length,
            };
        }

        const statusCounts = filteredCarriers.reduce(
            (counts, carrier) => {
                const carrierStatus =
                    carrier.currentYearStatus;

                if (selectedStatus.includes(carrier.currentYearStatus)) {
                    counts[carrierStatus] += 1;
                }

                return counts;
            },
            {
                [statusList.new]: 0,
                [statusList.published]: 0,
                [statusList.draft]: 0,
            }
        );

        return {
            newCount: statusCounts[statusList.new],
            publishedCount: statusCounts[statusList.published],
            draftCount: statusCounts[statusList.draft],
        };
    };

    // Gets the filtered insurance carriers.
    const setFilteredInsuranceCarriers = (searchValue: any) => {
        setSearch(searchValue);
        if (searchValue.length > 2) {
            totalCarriers?.filter(x => x?.insuranceCarrierName?.toLowerCase().includes(searchValue?.trim()?.toLowerCase())).length === 0 ? setIsNoSearchResult(true) : setIsNoSearchResult(false);
            setTotalCarriersCount(insuranceCarriers?.filter(x => x?.insuranceCarrierName?.toLowerCase().includes(searchValue?.toLowerCase()))
                .filter(x => {
                    if (selectedStatus.length === 0) {
                        return true; // No filterstatus, so include all carriers
                    }
                    return selectedStatus.includes(x.currentYearStatus);
                }).length ?? 0);
        }
        else {
            setIsNoSearchResult(false);
            if (inActiveFilter) {
                ActiveOrInActiveCarriers(ActiveInactiveList.inactive);
            }
            else if (activeFilter) {
                ActiveOrInActiveCarriers(ActiveInactiveList.active);
            }
            else if (isActiveFutureFilter) {
                ActiveOrInActiveCarriers(ActiveInactiveList.futureActive)
            }
            else if (isInActiveFutureFilter) {
                ActiveOrInActiveCarriers(ActiveInactiveList.futureInactive)
            }
            SetActiveInActiveFilter();
            setTotalCarriersCount(
                totalCarriers?.filter(x => {
                    if (selectedStatus.length === 0) {
                        return true; // No filterstatus, so include all carriers
                    }
                    return selectedStatus.includes(x.currentYearStatus);
                }).length ?? 0);

        }

    }


    const handletoggleFitlerVisibility = () => {
        setToggleFitlerVisibility(!toggleFitlerVisibility);
    };


    const handleStatusChange = (option: string) => {
        if (!selectedCheckBoxStatus.includes(option)) {
            setSelectedCheckBoxStatus([...selectedCheckBoxStatus, option]);
        }
        else {
            setSelectedCheckBoxStatus(selectedCheckBoxStatus.filter(item => item !== option));
        }
    };


    const handleClickevents = (event: React.MouseEvent<HTMLInputElement>) => {
        event.stopPropagation(); // Prevent the click event from propagating to the Dropdown.Item
    };


    const handleClearAll = (active: boolean) => {
        setIsFilterApplied(false);
        setSelectedCheckBoxStatus([]);
        setSelectedStatus([]); // Clear all selected status
        setFilteredStatus([]);
        setToggleFitlerVisibility(false);
        if (active && search?.length <= 2) {
            ActiveOrInActiveCarriers(ActiveInactiveList.active);
        }
        else if (active && search?.length > 2) {
            ActiveOrInActiveCarriers(ActiveInactiveList.all);
        }
    };

    const SetActiveInActive = (active: boolean, inactive: boolean, all: boolean, futureactive: boolean, futureinactive: boolean) => {
        setIsActive(active);
        setIsInActive(inactive);
        setIsAll(all);
        setIsActiveFuture(futureactive);
        setIsInActiveFuture(futureinactive);
    };

    const SetActiveInActiveFilter = () => {
        setActiveFilter(false);
        setInActiveFilter(false);
        setIsActiveFutureFilter(false);
        setIsInActiveFutureFilter(false);
    };

    const ActiveOrInActiveCarriers = (isactive: string) => {
        handleClearAll(false);
        setIsFilterApplied(true);
        let effYear = (isactive === ActiveInactiveList.futureActive || isactive === ActiveInactiveList.futureInactive) ? (year + 1) : year;
        let effFromDate = formatDate(effYear === year + 1 ? new Date(year + 1, 0, 1) : new Date());
        let effToDate = formatDate(new Date(effYear, 11, 31));
        switch (isactive) {
            case ActiveInactiveList.inactive:
                setInsuranceCarriers(totalCarriers?.filter(x => x.isActiveCarrier === false
                    && ((formatDate(new Date(x?.effectiveFromDate)) <= effFromDate || new Date(x?.effectiveFromDate).getFullYear() === effYear) && formatDate(new Date(x?.effectiveToDate)) >= effToDate)
                ));
                SetActiveInActive(false, true, false, false, false);
                RemoveKeyFromLocalStorage(BenefitYear);
                break;
            case ActiveInactiveList.active:
                setInsuranceCarriers(totalCarriers?.filter(x => x.isActiveCarrier === true
                    && ((formatDate(new Date(x?.effectiveFromDate)) <= effFromDate || new Date(x?.effectiveFromDate).getFullYear() === effYear) && formatDate(new Date(x?.effectiveToDate)) >= effToDate)
                ));
                SetActiveInActive(true, false, false, false, false);
                RemoveKeyFromLocalStorage(BenefitYear);
                break;
            case ActiveInactiveList.futureActive:
                setInsuranceCarriers(totalCarriers?.filter(x => x.futureIsActiveCarrier === true
                    && ((formatDate(new Date(x?.effectiveFromDate)) <= effFromDate || new Date(x?.effectiveFromDate).getFullYear() === effYear) && formatDate(new Date(x?.effectiveToDate)) >= effToDate)
                ));
                SetActiveInActive(false, false, false, true, false);
                SetKeyToLocalStorage(BenefitYear, (year + 1).toString());
                break;
            case ActiveInactiveList.futureInactive:
                setInsuranceCarriers(totalCarriers?.filter(x => x.futureIsActiveCarrier === false
                    && ((formatDate(new Date(x?.effectiveFromDate)) <= effFromDate || new Date(x?.effectiveFromDate).getFullYear() === effYear) && formatDate(new Date(x?.effectiveToDate)) >= effToDate)
                ));
                SetActiveInActive(false, false, false, false, true);
                SetKeyToLocalStorage(BenefitYear, (year + 1).toString());
                break;
            default:
                setInsuranceCarriers(totalCarriers);
                SetActiveInActive(false, false, true, false, false);
                RemoveKeyFromLocalStorage(BenefitYear);
                break;
        }
    }

    // Onchange event for searchbox.
    const getSearchtext = (e: any) => {
        SetKeyToLocalStorage(CarrierNameSearchText, e.target.value);
        setFilteredInsuranceCarriers(e.target.value);
        RemoveKeyFromLocalStorage(CarrierNameSearchText);
    }

    //Carrier Status for current and next year

    const filterbyStatusandYear = (filterstatus: string[]) => {
        if (isNoSearchResult === false && totalCarriersCount > 0 && isFilterApplied === true) {
            return insuranceCarriers && insuranceCarriers
                .filter(x => search?.length > 2 ? x?.insuranceCarrierName?.toLowerCase().includes(search?.toLowerCase()) : x?.insuranceCarrierName)
                .filter(x => {
                    //CurrentYearPublishedCount
                    if (filterstatus.length === 0) {
                        return true; // No filterstatus, so include all carriers
                    }
                    return filterstatus.includes(x.currentYearStatus ?? '');

                })
                .map((insurance: InsuranceCarriers, index: number) => {
                    let currentStatus = insurance?.currentYearStatus?.toLowerCase() === "inprogress" ? "review" : insurance?.currentYearStatus?.toLowerCase();
                    let futureYearStatus = insurance?.futureYearStatus?.toLowerCase() === "inprogress" ? "review" : insurance?.futureYearStatus?.toLowerCase();
                    return <tr key={index} onClick={() => handleInsuranceCarrierClick(insurance)}>
                        <td className="carriers" title={insurance.insuranceCarrierName}><span>{insurance.insuranceCarrierName}</span></td>
                        <td className="plans"><span>{insurance.publishedCount + "/" + insurance.plansCount}</span></td>
                        <td className="benefit-year">   <span className={`status ${currentStatus}`}>{insurance?.currentYearStatus}</span></td>
                        <td className="created" title={insurance.createUser}><span>{insurance.createUser}</span></td>
                        <td className="status-section carrier-status">
                            <span className={`status ${futureYearStatus}`}>{insurance?.futureYearStatus}</span>
                        </td>
                    </tr>
                });
        } else {
            return (
                <NotFound message={"No carriers found"} colSpan={5} />
            );
        }
    };


    // Enables you to handle side effects and perform actions in response to component rendering or changes in specific dependencies
    useEffect(() => {
        if (isFilterApplied && filteredStatus.length !== 0) {
            const newCount = getFilteredInsuranceCarrierscCounts().newCount;
            const draftCount = getFilteredInsuranceCarrierscCounts().draftCount;
            const publishedCount = getFilteredInsuranceCarrierscCounts().publishedCount;
            setTotalCarriersCount(newCount + draftCount + publishedCount);
        } else {
            setTotalCarriersCount(getFilteredInsuranceCarriersLength() ?? 0);
        }
    }, [isFilterApplied, filteredStatus])

    // Enables you to handle side effects and perform actions in response to component rendering or changes in specific dependencies
    useEffect(() => {
        setTotalCarriersCount(getFilteredInsuranceCarriersLength() ?? 0);
    }, [insuranceCarriers])

    const Applyfilters = () => {
        setInsuranceCarriers(totalCarriers);
        setIsFilterApplied(true);
        setSelectedStatus(selectedCheckBoxStatus);
        setFilteredStatus(selectedCheckBoxStatus);
        SetActiveInActive(false, false, false, false, false);
        filterbyStatusandYear(filteredStatus);
        setToggleFitlerVisibility(false);
    };

    // Render insurance carriers component.
    const renderInsuranceCarriers = () => {
        if (isNoSearchResult === false) {
            return insuranceCarriers && insuranceCarriers
                .filter(x => search?.length > 2 ? x?.insuranceCarrierName?.toLowerCase().includes(search?.trim()?.toLowerCase()) : x?.insuranceCarrierName)
                .map((insurance: InsuranceCarriers, index: number) => {
                    let currentStatus = insurance?.currentYearStatus?.toLowerCase() === "inprogress" ? "review" : insurance?.currentYearStatus?.toLowerCase();
                    let futureYearStatus = insurance?.futureYearStatus?.toLowerCase() === "inprogress" ? "review" : insurance?.futureYearStatus?.toLowerCase();
                    return <tr key={index} onClick={() => handleInsuranceCarrierClick(insurance)}>
                        <td className="carriers" title={insurance.insuranceCarrierName}><span>{insurance.insuranceCarrierName}</span></td>
                        <td className="plans"><span>{insurance.publishedCount + "/" + insurance.plansCount}</span></td>
                        <td className="status-section carrier-status">
                            <span className={`status ${currentStatus}`}>{insurance?.currentYearStatus}</span>
                        </td>
                        <td className="created" title={insurance.createUser}><span>{insurance.createUser}</span></td>
                        {/* Next year Staus*/}
                        <td className="status-section carrier-status">
                            <span className={`status ${futureYearStatus}`}>{insurance?.futureYearStatus}</span>
                        </td>
                    </tr>
                });
        } else {
            return (
                <NotFound message={"No carriers found"} colSpan={5} />
            );
        }
    };

    // Enables you to handle side effects and perform actions in response to component rendering or changes in specific dependencies
    useEffect(() => {
        // Function to clear localStorage
        const clearLocalStorage = () => {
            RemoveKeyFromLocalStorage(CarrierNameSearchText);
        };

        // Add the event listener to the 'beforeunload' event
        window.addEventListener('beforeunload', clearLocalStorage);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('beforeunload', clearLocalStorage);
        };

    }, []);

    // Enables you to handle side effects and perform actions in response to component rendering or changes in specific dependencies
    useEffect(() => {
        const fetchInsuranceCarriers = async () => {
            try {
                let effFromDate = formatDate(new Date());
                let effToDate = formatDate(new Date(year, 11, 31));
                const apiUrl = `${GetInsuranceCarriers}`;
                const response = await axios.get(apiUrl);
                setInsuranceCarriers(response?.data?.filter((x: any) => x.isActiveCarrier === true
                    && ((formatDate(new Date(x?.effectiveFromDate)) <= effFromDate || new Date(x?.effectiveFromDate).getFullYear() === year) && formatDate(new Date(x?.effectiveToDate)) >= effToDate)
                ));
                setTotalCarriers(response.data);
                setFilteredInsuranceCarriers(GetKeyValueFromLocalStorage(CarrierNameSearchText));
                setLoader(false);
            }
            catch (error) {
                console.log(error);
                setLoader(false);
            }
        }

        let isComponentMounted: boolean = true;

        if (isComponentMounted) {
            fetchInsuranceCarriers();
            isComponentMounted = false;
        }

        return () => {
            isComponentMounted = false;
        }
    }, [])

    // Determine if the user has an "Admin" role and render AccessDenied if needed
    const shouldRenderAccessDenied = (GetKeyValueFromLocalStorage("SelectedRole") !== undefined && GetKeyValueFromLocalStorage("SelectedRole")?.toLowerCase() !== Admin.toLowerCase() && GetKeyValueFromLocalStorage("SelectedRole")?.toLowerCase() !== Manager.toLowerCase() && GetKeyValueFromLocalStorage("SelectedRole")?.toLowerCase() !== SystemAdmin.toLowerCase());

    if (GetKeyValueFromLocalStorage("SelectedRole") === null || GetKeyValueFromLocalStorage("SelectedRole") === "") {
        // Role determination is still in progress, return loading or placeholder
        return <AccessDenied />
    }

    return shouldRenderAccessDenied ? (
        <AccessDenied />
    ) : ((
        <div className={`d-flex overflow-hidden ${(loader === true ? "loading-skeleton" : "")}`}>

            <SideBar />
            <div className="page-content">
                <div className='page-title-section'>
                    <div className="page-top-left">
                        <h3><a href=" " className="back-arrow"><img className="img-fluid" src={BackArrow} alt=" " /></a> Carriers</h3>
                        <ListGroup horizontal className="actions-icons d-lg-none" >
                            <ListGroup.Item>
                                <img className="img-fluid" src={FilterMobile} alt=" " onClick={(e: any) => { e.stopPropagation(); setShowFilters(true) }} />
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <img className="img-fluid" src={Search} alt=" " onClick={toggleSearchBox} />
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                        <div className='page-top-right'>
                            <Link to="#" className="custom-link d-flex align-items-center" onClick={openEmulateMember}>
                                <img className="img-fluid me-2 " src={ViewPrimary} alt=" " />
                                <span>Emulate Member</span>
                            </Link>
                        <div className="page-top-actions d-none">
                            <Button className="btn-secondary submit" href="/">
                                <img className="img-fluid me-2" src={Add} alt=" " /><span>Add Carrier</span>
                            </Button>

                            </div>
                            {/* Conditionally render the Emulate Member PopUp */}
                            {showEmulateMember && <EmulateMember onClose={closeEmulateMember} />}
                    </div>
                </div>
                <Tabs defaultActiveKey="AllCarriers" id="uncontrolled-tab-example" className="tabs-list">
                    <Tab eventKey="AllCarriers" title={`All Carriers (${isFilterApplied && totalCarriersCount === 0 ? getFilteredInsuranceCarriersLength() : totalCarriersCount})`}>
                        <div className='tabs-container'>
                            <div className="table-actions">
                                <div className="activity-buttons">
                                    <Button className={isAll ? "btn-outline active" : "btn-outline"} onClick={() => ActiveOrInActiveCarriers(ActiveInactiveList.all)} >All</Button>
                                    <Button className={isActive ? "btn-outline active" : "btn-outline"} onClick={() => ActiveOrInActiveCarriers(ActiveInactiveList.active)} >{year} Active</Button>
                                    <Button className={isInActive ? "btn-outline active" : "btn-outline"} onClick={() => ActiveOrInActiveCarriers(ActiveInactiveList.inactive)}>{year} Non-Active</Button>
                                    <Button className={isActiveFuture ? "btn-outline active" : "btn-outline"} onClick={() => ActiveOrInActiveCarriers(ActiveInactiveList.futureActive)}  >{year + 1} Active</Button>
                                    <Button className={isInActiveFuture ? "btn-outline active" : "btn-outline"} onClick={() => ActiveOrInActiveCarriers(ActiveInactiveList.futureInactive)} >{year + 1} Non-Active</Button>
                                    <Button className="d-none btn-outline">In Review</Button>
                                    <div className="filter-section" >
                                        <Dropdown show={toggleFitlerVisibility}>
                                            <Dropdown.Toggle variant="" id="dropdown-basic" className="filters" onClick={handletoggleFitlerVisibility}>
                                                <img className="img-fluid me-2" src={Filter} alt=" " />
                                                <span>Filters</span>
                                                <div className={isActive || isAll || isInActive || isActiveFuture || isInActiveFuture ? '' : 'filter-notification'}></div>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className="filter-dropdown">
                                                <h5 className="mb-3">Filter by</h5>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="" id="filterStatus" className="form-field w-100">
                                                        <FloatingLabel id="filterStatus" label="Status" className="form-field w-100">
                                                            <input className="form-control"
                                                                placeholder="Status"
                                                                data-bs-toggle="dropdown"
                                                                data-bs-display="static"
                                                                aria-expanded="false"
                                                                value={selectedfilterStatusText} />
                                                            <img className="img-fluid arrow" src={DownArrow} alt=" " />
                                                        </FloatingLabel>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {statusOptions.map(option => {
                                                            return <Dropdown.ItemText key={option} onClick={handleClickevents}>
                                                                <div className="input-box">
                                                                    <Form.Check aria-label="option 1"
                                                                        className="form-check" id={option}
                                                                        checked={selectedCheckBoxStatus.includes(option)}
                                                                        onChange={() => handleStatusChange(option)}
                                                                        onClick={handleClickevents}
                                                                    />
                                                                    <label htmlFor={option}>{option}</label>
                                                                </div>
                                                            </Dropdown.ItemText>
                                                        })}

                                                    </Dropdown.Menu>
                                                </Dropdown>

                                                <div className="filter-buttons">
                                                    <Button className="btn-secondary btn-sm" onClick={Applyfilters} >Apply</Button>
                                                    <Button className="btn-sm btn-outline-primary" onClick={() => handleClearAll(true)} >Clear All</Button>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <span className="filter-result">Showing {isFilterApplied && totalCarriersCount === 0 ? getFilteredInsuranceCarriersLength() : totalCarriersCount} carriers</span>
                                    </div>
                                        {(screenWidth >= 991 || showSearchBox) && (
                                            <div className="form-field table-search features-search">
                                                <FloatingLabel controlId="floatingInput" label="Search Carriers" className="m-0">
                                                    <input className="form-control search-input"
                                                        type="search"
                                                        placeholder="Search Carriers"
                                                        aria-label="Search"
                                                        data-bs-toggle="dropdown"
                                                        data-bs-display="static"
                                                        aria-expanded="false"
                                                        onChange={getSearchtext} value={search} />
                                                    {search?.length === 0 ? <Image src={Search} className="form-field-icon" alt="" /> : <></>}
                                                </FloatingLabel>
                                            </div>
                                        )}           
                            </div>
                            <div className="table-responsive table-radius">
                                <Scrollbars
                                    autoHide
                                    autoHideTimeout={100}
                                    autoHideDuration={200}
                                    thumbMinSize={80}
                                    autoHeight
                                    autoHeightMin={60}
                                    autoHeightMax={690}>
                                    <Table className='table-hover'>

                                        <thead>
                                            <tr>
                                                <th className="carriers">Carriers</th>
                                                <th className="plans">{year} Published Plans</th>
                                                <th className="status-section carrier-status">Benefits - {year}</th>
                                                <th className="created">Created by</th>
                                                <th className="status-section carrier-status">Benefits - {year + 1}</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {loader ? (
                                                Array.from({ length: 15 }).map((_, index) => (
                                                    <tr key={index}>
                                                        <td className="carriers"></td>
                                                        <td className="plans"></td>
                                                        <td className="benefit-year"></td>
                                                        <td className="created"></td>
                                                        <td className="status-section carrier-status"></td>
                                                    </tr>
                                                ))
                                            ) : (
                                                isFilterApplied ?
                                                    filterbyStatusandYear(filteredStatus) : renderInsuranceCarriers()
                                            )}

                                        </tbody>
                                    </Table>
                                </Scrollbars>


                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
            {/* Filters Modal Popup */}
            <Modal className="modal-large bottom filters-modal" show={showFilters} backdrop="static" keyboard={false} size="xl" centered>
                <Modal.Header closeButton onClick={handleFiltersClose}>
                    <Modal.Title>Filters</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>Status</h6>
                    <div className="status-filter">
                        <div className="input-box">
                            <Form.Check className="form-check" name="group1" type={'radio'} id={`radio-1`} label={`Published`} />
                        </div>
                        <div className="input-box">
                            <Form.Check className="form-check" name="group1" type={'radio'} id={`radio-2`} label={`Approved`} />
                        </div>
                        <div className="input-box">
                            <Form.Check className="form-check" name="group1" type={'radio'} id={`radio-3`} label={`In Review`} />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-start">
                    <Button className="btn-secondary" onClick={handleFiltersClose}>Apply Filters</Button>
                    <Link to="#" className="custom-link" onClick={handleFiltersClose}>Reset</Link>
                </Modal.Footer>
            </Modal>
        </div>
    ))
}